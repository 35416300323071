<template> 
  <div> 
    <div class="d-flex flex-stack flex-wrap">
        <div class="page-title d-flex flex-column me-3 mt-3"> 
            <h1 class="d-flex text-dark fw-bolder my-1 fs-3">General Settings</h1>  
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary">
                        <router-link :to="{ name: 'Home' }">Home</router-link>
                    </a>
                </li> 
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li> 
                <li class="breadcrumb-item text-gray-600">General Settings</li> 
            </ul> 
        </div>  
        <div class="d-flex align-items-center py-1">
            <router-link to="/Settings">
                <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                    <span class="ms-2 fs-7"> Return back to Settings </span>
                </button>
            </router-link>
        </div>
    </div>
    <div class="content flex-row-fluid mt-4" id="kt_content"> 
         <div class="row g-6 g-xl-9 text-center p-6 pt-2 mt-6 pb-12 bg-white bgi-no-repeat bgi-position-y-top bgi-position-x-end shadow rounded min-h-220px" 
              style="background-image: url('assets/theme/media/svg/shapes/abstract-2.svg')"> 
            <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                <router-link to="/AccountingPlans">
                    <a href="" class="card border-hover-danger h-150px px-md-3 sub-settings-card-hover border p-10 bg-light-primary">
                        <h2>
                            <span class="svg-icon svg-icon-cyan svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M17.1 10.5H11.1C10.5 10.5 10.1 10.1 10.1 9.5V8.5C10.1 7.9 10.5 7.5 11.1 7.5H17.1C17.7 7.5 18.1 7.9 18.1 8.5V9.5C18.1 10.1 17.7 10.5 17.1 10.5ZM22.1 4.5V3.5C22.1 2.9 21.7 2.5 21.1 2.5H11.1C10.5 2.5 10.1 2.9 10.1 3.5V4.5C10.1 5.1 10.5 5.5 11.1 5.5H21.1C21.7 5.5 22.1 5.1 22.1 4.5ZM22.1 15.5V14.5C22.1 13.9 21.7 13.5 21.1 13.5H11.1C10.5 13.5 10.1 13.9 10.1 14.5V15.5C10.1 16.1 10.5 16.5 11.1 16.5H21.1C21.7 16.5 22.1 16.1 22.1 15.5ZM18.1 20.5V19.5C18.1 18.9 17.7 18.5 17.1 18.5H11.1C10.5 18.5 10.1 18.9 10.1 19.5V20.5C10.1 21.1 10.5 21.5 11.1 21.5H17.1C17.7 21.5 18.1 21.1 18.1 20.5Z" fill="currentColor"/>
                            <path d="M5.60001 10.5C5.30001 10.5 5.00002 10.4 4.80002 10.2C4.60002 9.99995 4.5 9.70005 4.5 9.30005V5.40002C3.7 5.90002 3.40001 6 3.10001 6C2.90001 6 2.6 5.89995 2.5 5.69995C2.3 5.49995 2.20001 5.3 2.20001 5C2.20001 4.6 2.4 4.40005 2.5 4.30005C2.6 4.20005 2.80001 4.10002 3.10001 3.90002C3.50001 3.70002 3.8 3.50005 4 3.30005C4.2 3.10005 4.40001 2.89995 4.60001 2.69995C4.80001 2.39995 4.9 2.19995 5 2.19995C5.1 2.09995 5.30001 2 5.60001 2C5.90001 2 6.10002 2.10002 6.30002 2.40002C6.50002 2.60002 6.5 2.89995 6.5 3.19995V9C6.6 10.4 5.90001 10.5 5.60001 10.5ZM7.10001 21.5C7.40001 21.5 7.69999 21.4 7.89999 21.2C8.09999 21 8.20001 20.8 8.20001 20.5C8.20001 20.2 8.10002 19.9 7.80002 19.8C7.60002 19.6 7.3 19.6 7 19.6H5.10001C5.30001 19.4 5.50002 19.2 5.80002 19C6.30002 18.6 6.69999 18.3 6.89999 18.1C7.09999 17.9 7.40001 17.6 7.60001 17.2C7.80001 16.8 8 16.3 8 15.9C8 15.6 7.90002 15.3 7.80002 15C7.70002 14.7 7.50002 14.5 7.30002 14.2C7.10002 14 6.80001 13.8 6.60001 13.7C6.20001 13.5 5.70001 13.4 5.10001 13.4C4.60001 13.4 4.20002 13.5 3.80002 13.6C3.40002 13.7 3.09999 13.9 2.89999 14.2C2.69999 14.4 2.50002 14.7 2.30002 15C2.20002 15.3 2.10001 15.6 2.10001 15.9C2.10001 16.3 2.29999 16.5 2.39999 16.6C2.59999 16.8 2.80001 16.9 3.10001 16.9C3.50001 16.9 3.70002 16.7 3.80002 16.6C3.90002 16.4 4.00001 16.2 4.10001 16C4.20001 15.8 4.20001 15.7 4.20001 15.7C4.40001 15.4 4.59999 15.3 4.89999 15.3C4.99999 15.3 5.20002 15.3 5.30002 15.4C5.40002 15.5 5.50001 15.5 5.60001 15.7C5.70001 15.8 5.70001 15.9 5.70001 16.1C5.70001 16.2 5.70001 16.4 5.60001 16.6C5.50001 16.8 5.40001 16.9 5.20001 17.1C5.00001 17.3 4.80001 17.5 4.60001 17.6C4.40001 17.7 4.20002 17.9 3.80002 18.3C3.40002 18.6 3.00001 19 2.60001 19.5C2.50001 19.6 2.30001 19.8 2.20001 20.1C2.10001 20.4 2 20.6 2 20.7C2 21 2.10002 21.3 2.30002 21.5C2.50002 21.7 2.80001 21.8 3.20001 21.8H7.10001V21.5Z" fill="currentColor"/>
                            </svg>
                            </span>
                        </h2>
                        <h5 class="card-title card-title-custom fs-7 mt-3">
                            Account Plans
                        </h5>
                    </a>
                </router-link>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                <router-link to="/DashboardFolders">                    
                    <a href="" class="card border-hover-danger h-150px px-md-3 sub-settings-card-hover p-10 px-8 bg-light-primary">
                        <h2> 
                            <span class="svg-icon svg-icon-cyan svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor"/>
                            <path d="M9.2 3H3C2.4 3 2 3.4 2 4V19C2 19.6 2.4 20 3 20H21C21.6 20 22 19.6 22 19V7C22 6.4 21.6 6 21 6H12L10.4 3.60001C10.2 3.20001 9.7 3 9.2 3Z" fill="currentColor"/>
                            </svg>
                            </span>
                        </h2>   
                        <h5 class="card-title card-title-custom fs-7 mt-3">
                            Company Dashboard Folders
                        </h5>
                    </a>
                </router-link>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                <router-link to="/CompanyManagement">
                    <a href="" class="card border-hover-danger h-150px px-md-3 sub-settings-card-hover border p-10 bg-light-primary">
                        <h2>
                            <span class="svg-icon svg-icon-deep-orange svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M20 15H4C2.9 15 2 14.1 2 13V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V13C22 14.1 21.1 15 20 15ZM13 12H11C10.5 12 10 12.4 10 13V16C10 16.5 10.4 17 11 17H13C13.6 17 14 16.6 14 16V13C14 12.4 13.6 12 13 12Z" fill="currentColor"/>
                            <path d="M14 6V5H10V6H8V5C8 3.9 8.9 3 10 3H14C15.1 3 16 3.9 16 5V6H14ZM20 15H14V16C14 16.6 13.5 17 13 17H11C10.5 17 10 16.6 10 16V15H4C3.6 15 3.3 14.9 3 14.7V18C3 19.1 3.9 20 5 20H19C20.1 20 21 19.1 21 18V14.7C20.7 14.9 20.4 15 20 15Z" fill="currentColor"/>
                            </svg>
                            </span>
                        </h2>
                        <h5 class="card-title card-title-custom fs-7 mt-3">
                            Company Management
                        </h5>
                    </a>
                </router-link>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                <router-link to="/DocumentLocationList">
                    
                    <a href="" class="card border-hover-danger h-150px px-md-3 sub-settings-card-hover p-10 bg-light-primary">
                        <h2> 
                            <span class="svg-icon svg-icon-cyan svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor"/>
                            <path d="M9.2 3H3C2.4 3 2 3.4 2 4V19C2 19.6 2.4 20 3 20H21C21.6 20 22 19.6 22 19V7C22 6.4 21.6 6 21 6H12L10.4 3.60001C10.2 3.20001 9.7 3 9.2 3Z" fill="currentColor"/>
                            </svg>
                            </span>
                        </h2>   
                        <h5 class="card-title card-title-custom fs-7 mt-3">
                            Document Locations
                        </h5>
                    </a>
                </router-link>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                <router-link to="/DocumentTypeList">
                    
                    <a href="" class="card border-hover-danger h-150px px-md-3 sub-settings-card-hover p-10 bg-light-primary">
                        <h2> 
                            <span class="svg-icon svg-icon-cyan svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor"/>
                            <path d="M9.2 3H3C2.4 3 2 3.4 2 4V19C2 19.6 2.4 20 3 20H21C21.6 20 22 19.6 22 19V7C22 6.4 21.6 6 21 6H12L10.4 3.60001C10.2 3.20001 9.7 3 9.2 3Z" fill="currentColor"/>
                            </svg>
                            </span>
                        </h2>   
                        <h5 class="card-title card-title-custom fs-7 mt-3">
                            Document Types
                        </h5>
                    </a>
                </router-link>
            </div> 
            <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12 ribbon ribbon-top" v-if="this.$isSystemAdmin()">
                <div class="ribbon-label bg-primary fs-9">System Admin</div>
                <router-link to="/PagesList">
                    <a href="" class="card border-hover-danger h-150px px-md-3 sub-settings-card-hover border p-10 bg-light-primary">
                        <h2> 
                            <span class="svg-icon svg-icon-primary svg-icon-2hx"><svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V2.40002C0 3.00002 0.4 3.40002 1 3.40002H12C12.6 3.40002 13 3.00002 13 2.40002V1.40002C13 0.800024 12.6 0.400024 12 0.400024Z" fill="currentColor"/>
                            <path opacity="0.3" d="M15 8.40002H1C0.4 8.40002 0 8.00002 0 7.40002C0 6.80002 0.4 6.40002 1 6.40002H15C15.6 6.40002 16 6.80002 16 7.40002C16 8.00002 15.6 8.40002 15 8.40002ZM16 12.4C16 11.8 15.6 11.4 15 11.4H1C0.4 11.4 0 11.8 0 12.4C0 13 0.4 13.4 1 13.4H15C15.6 13.4 16 13 16 12.4ZM12 17.4C12 16.8 11.6 16.4 11 16.4H1C0.4 16.4 0 16.8 0 17.4C0 18 0.4 18.4 1 18.4H11C11.6 18.4 12 18 12 17.4Z" fill="currentColor"/>
                            </svg>
                            </span>
                        </h2>
                        <h5 class="card-title card-title-custom fs-7 mt-3">
                           Help Pages
                        </h5>
                    </a>
                </router-link>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                <router-link to="/ListTypes">
                    <a href="" class="card border-hover-danger h-150px px-md-3 sub-settings-card-hover border p-10 bg-light-primary">
                        <h2>
                            <span class="svg-icon svg-icon-danger svg-icon-2hx"><svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V2.40002C0 3.00002 0.4 3.40002 1 3.40002H12C12.6 3.40002 13 3.00002 13 2.40002V1.40002C13 0.800024 12.6 0.400024 12 0.400024Z" fill="currentColor"/>
                            <path opacity="0.3" d="M15 8.40002H1C0.4 8.40002 0 8.00002 0 7.40002C0 6.80002 0.4 6.40002 1 6.40002H15C15.6 6.40002 16 6.80002 16 7.40002C16 8.00002 15.6 8.40002 15 8.40002ZM16 12.4C16 11.8 15.6 11.4 15 11.4H1C0.4 11.4 0 11.8 0 12.4C0 13 0.4 13.4 1 13.4H15C15.6 13.4 16 13 16 12.4ZM12 17.4C12 16.8 11.6 16.4 11 16.4H1C0.4 16.4 0 16.8 0 17.4C0 18 0.4 18.4 1 18.4H11C11.6 18.4 12 18 12 17.4Z" fill="currentColor"/>
                            </svg>
                            </span>
                        </h2>
                        <h5 class="card-title card-title-custom fs-7 mt-3">
                            List Types
                        </h5>
                    </a>
                </router-link>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12 ribbon ribbon-top" v-if="this.$isSystemAdmin()">
                <div class="ribbon-label bg-primary fs-9">System Admin</div>
                <router-link to="/MediaLibrary">
                    <a href="" class="card border-hover-danger h-150px px-md-3 sub-settings-card-hover border p-10 bg-light-primary">
                        <h2> 
                            <span class="svg-icon svg-icon-primary svg-icon-2hx"><svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V2.40002C0 3.00002 0.4 3.40002 1 3.40002H12C12.6 3.40002 13 3.00002 13 2.40002V1.40002C13 0.800024 12.6 0.400024 12 0.400024Z" fill="currentColor"/>
                            <path opacity="0.3" d="M15 8.40002H1C0.4 8.40002 0 8.00002 0 7.40002C0 6.80002 0.4 6.40002 1 6.40002H15C15.6 6.40002 16 6.80002 16 7.40002C16 8.00002 15.6 8.40002 15 8.40002ZM16 12.4C16 11.8 15.6 11.4 15 11.4H1C0.4 11.4 0 11.8 0 12.4C0 13 0.4 13.4 1 13.4H15C15.6 13.4 16 13 16 12.4ZM12 17.4C12 16.8 11.6 16.4 11 16.4H1C0.4 16.4 0 16.8 0 17.4C0 18 0.4 18.4 1 18.4H11C11.6 18.4 12 18 12 17.4Z" fill="currentColor"/>
                            </svg>
                            </span>
                        </h2>
                        <h5 class="card-title card-title-custom fs-7 mt-3">
                           Media Library
                        </h5>
                    </a>
                </router-link>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                <router-link to="/PolicyListTypes">
                    <a href="" class="card border-hover-danger h-150px px-md-3 sub-settings-card-hover border p-10 bg-light-primary">
                        <h2>
                            <span class="svg-icon svg-icon-warning opacity-75 svg-icon-2hx">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M18 21.6C16.3 21.6 15 20.3 15 18.6V2.50001C15 2.20001 14.6 1.99996 14.3 2.19996L13 3.59999L11.7 2.3C11.3 1.9 10.7 1.9 10.3 2.3L9 3.59999L7.70001 2.3C7.30001 1.9 6.69999 1.9 6.29999 2.3L5 3.59999L3.70001 2.3C3.50001 2.1 3 2.20001 3 3.50001V18.6C3 20.3 4.3 21.6 6 21.6H18Z" fill="currentColor"/>
                                    <path d="M12 12.6H11C10.4 12.6 10 12.2 10 11.6C10 11 10.4 10.6 11 10.6H12C12.6 10.6 13 11 13 11.6C13 12.2 12.6 12.6 12 12.6ZM9 11.6C9 11 8.6 10.6 8 10.6H6C5.4 10.6 5 11 5 11.6C5 12.2 5.4 12.6 6 12.6H8C8.6 12.6 9 12.2 9 11.6ZM9 7.59998C9 6.99998 8.6 6.59998 8 6.59998H6C5.4 6.59998 5 6.99998 5 7.59998C5 8.19998 5.4 8.59998 6 8.59998H8C8.6 8.59998 9 8.19998 9 7.59998ZM13 7.59998C13 6.99998 12.6 6.59998 12 6.59998H11C10.4 6.59998 10 6.99998 10 7.59998C10 8.19998 10.4 8.59998 11 8.59998H12C12.6 8.59998 13 8.19998 13 7.59998ZM13 15.6C13 15 12.6 14.6 12 14.6H10C9.4 14.6 9 15 9 15.6C9 16.2 9.4 16.6 10 16.6H12C12.6 16.6 13 16.2 13 15.6Z" fill="currentColor"/>
                                    <path d="M15 18.6C15 20.3 16.3 21.6 18 21.6C19.7 21.6 21 20.3 21 18.6V12.5C21 12.2 20.6 12 20.3 12.2L19 13.6L17.7 12.3C17.3 11.9 16.7 11.9 16.3 12.3L15 13.6V18.6Z" fill="currentColor"/>
                                </svg>
                            </span>
                        </h2>
                        <h5 class="card-title card-title-custom fs-7 mt-3">
                           Policy List Types
                        </h5>
                    </a>
                </router-link>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                <router-link to="/TaskManagement">
                    <a href="" class="card border-hover-danger h-150px px-md-3 sub-settings-card-hover border p-10 bg-light-primary">
                        <h2>
                            <span class="svg-icon svg-icon-indigo svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M6 22H4V3C4 2.4 4.4 2 5 2C5.6 2 6 2.4 6 3V22Z" fill="currentColor"/>
                            <path d="M18 14H4V4H18C18.8 4 19.2 4.9 18.7 5.5L16 9L18.8 12.5C19.3 13.1 18.8 14 18 14Z" fill="currentColor"/>
                            </svg>
                            </span>
                        </h2>
                        <h5 class="card-title card-title-custom fs-7 mt-3">
                            Task Management
                        </h5>
                    </a>
                </router-link>
            </div> 
        </div>
    </div>  
  </div>  
</template>

<script>
// import axios from 'axios' 
// import Swal from 'sweetalert2';
   

 
export default {
  name: "GeneralSettings", 
  data() {
    return {
      lists:[],
      searchbar: '',
      activeMenu: 'Home'
    };
  }, 
 
  methods: {
        getData() {
            this.activeMenu = this.$route.params.type;
        },
        onClickMenu(param) {
            this.activeMenu = param;
        }
    }, 
    created() {
    if(this.$getSessionStorage('refreshList')){
        this.getData();
    this.$removeSessionStorage('refreshList'); 
    }
    else{
        this.lists = [];
    }
  }
}; 
</script>