<template>
    <div>
        <div class="d-flex align-items-center">
            <div class="d-flex flex-column-fluid flex-row p-5"> 
                <h1 class="d-flex text-dark fw-bolder my-1 fs-3">
                    Media Library
                </h1>
            </div>
            <div class="d-flex align-items-center">
                <router-link to="/GeneralSettings">
                    <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                    <span class="ms-2 fs-7"> Return back to Settings </span>
                    </button>
                </router-link>
            </div> 
        </div>
            <div class="ms-5"> 
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">Home</router-link>
                        </a>
                    </li> 
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li> 
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'GeneralSettings' }">General Settings</router-link></li> 
                    <li class="breadcrumb-item text-gray-600">Media Library</li> 
                </ul>
            </div> 
        <div class="content flex-row-fluid py-4 px-3">
            <div class="row">
                <div class="d-flex flex-column flex-row-auto col-lg-4">
                    <div class="card border border-gray-300 h-750px">
                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-5 mb-xxl-10 rounded-3"> 
                            <div class="card-header">
                                <div class="card-title">
                                    <h4 class="text-gray-800 fs-3">
                                        Folder List
                                    </h4>
                                </div>
                                <div class="justify-content-end mt-4">
                                    <button type="button" class="btn btn-light-primary me-3" data-bs-toggle="modal" data-bs-target="#add_folder_modal" @click="this.openFolderModal()">
                                        <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor"></path>
                                        <path d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.2C9.7 3 10.2 3.20001 10.4 3.60001ZM16 12H13V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V12H8C7.4 12 7 12.4 7 13C7 13.6 7.4 14 8 14H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z" fill="currentColor"></path>
                                        <path opacity="0.3" d="M11 14H8C7.4 14 7 13.6 7 13C7 12.4 7.4 12 8 12H11V14ZM16 12H13V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z" fill="currentColor"></path>
                                        </svg>
                                        </span>
                                        New Folder
                                    </button>
                                </div>
                            </div>
                            <div class="card card-xxl-stretch" v-if="mediaFolderList.length > 0">
                                <div class="card-body p-0">
                                    <div class="scroll-y me-n5 pe-5 h-500px h-xl-auto" style="max-height: 650px;">
                                        <div class="table-responsive ms-5 me-5">
                                            <table class="table gs-2">
                                                <thead class="ms-3">
                                                    <tr class="text-gray-800">
                                                        <th class="text-gray-400 fw-bold fs-6 text-uppercase gs-0">Folder Name</th>
                                                            <th class="text-end text-gray-400 fw-bold fs-6 text-uppercase gs-0 ms-3">Created At</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="mediaFolderList">
                                                    <tr class="fs-8 cursor-pointer" v-for="folderItem in mediaFolderList" v-bind:key="folderItem" 
                                                        @click="this.onFolderOpened(folderItem)" :class="{ 'bg-light-primary' : this.activeFolder.id == folderItem.id }">
                                                        <td class="text-gray-900 fs-6 text-hover-primary fw-bolder">
                                                            <span class="svg-icon svg-icon-cyan svg-icon-2x"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor"/>
                                                            <path d="M9.2 3H3C2.4 3 2 3.4 2 4V19C2 19.6 2.4 20 3 20H21C21.6 20 22 19.6 22 19V7C22 6.4 21.6 6 21 6H12L10.4 3.60001C10.2 3.20001 9.7 3 9.2 3Z" fill="currentColor"/>
                                                            </svg>
                                                            </span>
                                                            {{ folderItem.folder_name }}
                                                        </td>
                                                        <td class="text-end text-gray-900 fs-6 text-hover-primary ms-3 fw-bolder">
                                                            {{ previewDate(folderItem.created_at ) }}
                                                        </td>
                                                        <td class="mt-2">
                                                            <a href="#" @click="this.deleteMediaFolder(folderItem)"><span class="svg-icon svg-icon-muted svg-icon-2 text-danger"><svg width="24" height="24" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"/>
                                                            <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"/>
                                                            <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"/>
                                                            </svg>
                                                            </span></a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column flex-row-auto col-lg-8">
                    <div class="card border border-gray-300 h-750px">
                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-5 mb-xxl-10 rounded-3"> 
                            <div class="card-header">
                                <div class="card-title">
                                    <h4 class="text-gray-800" v-if=" mediaFolderList.length > 0">
                                        <span class="svg-icon svg-icon-cyan svg-icon-2x"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor"/>
                                        <path d="M9.2 3H3C2.4 3 2 3.4 2 4V19C2 19.6 2.4 20 3 20H21C21.6 20 22 19.6 22 19V7C22 6.4 21.6 6 21 6H12L10.4 3.60001C10.2 3.20001 9.7 3 9.2 3Z" fill="currentColor"/>
                                        </svg>
                                        </span>
                                        {{this.activeFolder.folder_name}} 
                                    </h4> 
                                </div>
                            </div>
                            <div class="card card-flush">
                                <div class="card-header pt-8">
                                    <div class="card-title">
                                        <div class="d-flex align-items-center position-relative my-1">
                                            <span class="svg-icon svg-icon-1 position-absolute ms-6"><svg width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                                        transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                                    <path
                                                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                        fill="currentColor"></path>
                                                </svg>
                                            </span> 
                                            <input type="text" data-kt-customer-table-filter="search" class="form-control form-control-solid w-300px ps-15" placeholder="Search" v-model="searchbar">
                                        </div>
                                    </div>
                                    <div class="card-toolbar">
                                        <div class="d-flex justify-content-end" data-kt-filemanager-table-toolbar="base">
                                            <button type="button" class="btn btn-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_upload_form"  @click="this.openUploadMedia()" :disabled ="this.isViewUploadButton == false">
                                                <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor"></path>
                                                        <path
                                                            d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM16 11.6L12.7 8.29999C12.3 7.89999 11.7 7.89999 11.3 8.29999L8 11.6H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H16Z"
                                                            fill="currentColor"></path>
                                                        <path opacity="0.3" d="M11 11.6V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H11Z"
                                                            fill="currentColor"></path>
                                                    </svg>
                                                </span>
                                                Upload File
                                            </button>
                                        </div>
                                    </div>
                                </div> 
                                <div class="card-body pt-3">
                                    <div class="scroll-y me-n5 pe-5 h-500px h-xl-auto">
                                    <div class="table-responsive mh-550px">
                                        <table id="kt_profile_overview_table" class="table table-row-bordered table-row-dashed table-hover gy-4 align-middle fw-bold dataTable no-footer">
                                            <thead class="fs-6 text-gray-400 text-uppercase">
                                                <tr>
                                                    <th class="text-gray-400">#</th>
                                                    <th class="text-gray-400 min-w-250px">Document Name</th>
                                                    <th class="text-gray-400 min-w-200px">Size </th>
                                                    <th class="text-gray-400 min-w-200px">Extension </th>
                                                    <th class="text-gray-400 min-w-90px">Created At </th>
                                                </tr>
                                            </thead>
                                            <tbody class="fs-6" v-if="imageList">
                                                <tr class="odd" v-for="imageItem in searchableImageList" v-bind:key="imageItem" >
                                                    <td class="fs-7">
                                                        <img class="img-responsive cursor-pointer" width="60" height="60"
                                                        :src= "imageItem.url" v-if="imageItem.extension == 'jpg'"/>

                                                        <img class="img-responsive cursor-pointer" width="60" height="60"
                                                        :src= "imageItem.url" v-if="imageItem.extension ==  'png' "/>

                                                        <img class="img-responsive cursor-pointer" width="60" height="60"
                                                        :src= "imageItem.url" v-if="imageItem.extension == 'jpeg'"/>

                                                        <img class="img-responsive cursor-pointer" width="60" height="60"
                                                        :src= "imageItem.url" v-if="imageItem.extension == 'gif'"/>
                                                        
                                                        <img class="img-responsive cursor-pointer"
                                                            src="/assets/theme/media/extensionImage/video.png"
                                                            style="margin-right: auto; margin-left: auto;"
                                                            v-if="imageItem.extension == 'mp4'" /> 
                                                        <img class="img-responsive cursor-pointer"
                                                            src="/assets/theme/media/extensionImage/doc.png"
                                                            v-if="imageItem.extension == 'doc'"
                                                            style="margin-right: auto; margin-left: auto;" />
            
                                                        <img class="img-responsive cursor-pointer"
                                                            src="/assets/theme/media/extensionImage/docx.png"
                                                            v-if="imageItem.extension == 'docx'"
                                                            style="margin-right: auto; margin-left: auto;" />
            
                                                        <img class="img-responsive cursor-pointer"
                                                            src="/assets/theme/media/extensionImage/pdf.png"
                                                            v-if="imageItem.extension == 'pdf'"
                                                            style="margin-right: auto; margin-left: auto;" />
            
                                                        <img class="img-responsive cursor-pointer"
                                                            src="/assets/theme/media/extensionImage/ppt.png"
                                                            v-if="imageItem.extension == 'ppt'"
                                                            style="margin-right: auto; margin-left: auto;" />
            
                                                        <img class="img-responsive cursor-pointer"
                                                            src="/assets/theme/media/extensionImage/ppt.png"
                                                            v-if="imageItem.extension == 'pptx'"
                                                            style="margin-right: auto; margin-left: auto;" />
            
                                                        <img class="img-responsive cursor-pointer"
                                                            src="/assets/theme/media/extensionImage/rar.png"
                                                            v-if="imageItem.extension == 'rar'"
                                                            style="margin-right: auto; margin-left: auto;" />
            
                                                        <img class="img-responsive cursor-pointer"
                                                            src="/assets/theme/media/extensionImage/rtf.png"
                                                            v-if="imageItem.extension == 'rtf'"
                                                            style="margin-right: auto; margin-left: auto;" />
            
                                                        <img class="img-responsive cursor-pointer"
                                                            src="/assets/theme/media/extensionImage/txt.png"
                                                            v-if="imageItem.extension == 'txt'"
                                                            style="margin-right: auto; margin-left: auto;" />
            
                                                        <img class="img-responsive cursor-pointer"
                                                            src="/assets/theme/media/extensionImage/xls.png"
                                                            v-if="imageItem.extension == 'xls'"
                                                            style="margin-right: auto; margin-left: auto;" />
            
                                                        <img class="img-responsive cursor-pointer"
                                                            src="/assets/theme/media/extensionImage/xlsx.png"
                                                            v-if="imageItem.extension == 'xlsx'"
                                                            style="margin-right: auto; margin-left: auto;" />
            
                                                        <img class="img-responsive cursor-pointer"
                                                            src="/assets/theme/media/extensionImage/zip.png"
                                                            v-if="imageItem.extension == 'zip'"
                                                            style="margin-right: auto; margin-left: auto;" />

                                                    </td>
                                                    <td>
                                                         <div class="d-flex align-items-center"> 
                                                            <div class="d-flex flex-column justify-content-center">
                                                                <span class="fs-6 text-gray-800">{{ imageItem.name }}</span>
                                                            </div>
                                                        </div>
                                                        </td>
                                                        <td class="fs-7">{{calculateSize(imageItem.size) }}</td>
                                                        <td class="fs-7">{{ imageItem.extension }}</td>
                                                        <td class="fs-7">{{ previewDate(imageItem.created_at ) }}</td>
                                                        <td class="text-gray-800 fs-6 text-hover-primary" data-kt-filemanager-table="dropdown dropup">
                                                            <div class="d-flex ">
                                                                <div>
                                                                    <!-- TO - DO:  modala çevirilecek -->
                                                                    <button type="button" class="btn btn-sm btn-icon btn-light btn-active-light-primary"  data-bs-toggle="modal" data-bs-target="#open_download_modal" @click="this.onCopyLink(imageItem)">
                                                                        <span class="svg-icon svg-icon-5 m-0">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path opacity="0.3" d="M18.4 5.59998C18.7766 5.9772 18.9881 6.48846 18.9881 7.02148C18.9881 7.55451 18.7766 8.06577 18.4 8.44299L14.843 12C14.466 12.377 13.9547 12.5887 13.4215 12.5887C12.8883 12.5887 12.377 12.377 12 12C11.623 11.623 11.4112 11.1117 11.4112 10.5785C11.4112 10.0453 11.623 9.53399 12 9.15698L15.553 5.604C15.9302 5.22741 16.4415 5.01587 16.9745 5.01587C17.5075 5.01587 18.0188 5.22741 18.396 5.604L18.4 5.59998ZM20.528 3.47205C20.0614 3.00535 19.5074 2.63503 18.8977 2.38245C18.288 2.12987 17.6344 1.99988 16.9745 1.99988C16.3145 1.99988 15.661 2.12987 15.0513 2.38245C14.4416 2.63503 13.8876 3.00535 13.421 3.47205L9.86801 7.02502C9.40136 7.49168 9.03118 8.04568 8.77863 8.6554C8.52608 9.26511 8.39609 9.91855 8.39609 10.5785C8.39609 11.2384 8.52608 11.8919 8.77863 12.5016C9.03118 13.1113 9.40136 13.6653 9.86801 14.132C10.3347 14.5986 10.8886 14.9688 11.4984 15.2213C12.1081 15.4739 12.7616 15.6039 13.4215 15.6039C14.0815 15.6039 14.7349 15.4739 15.3446 15.2213C15.9543 14.9688 16.5084 14.5986 16.975 14.132L20.528 10.579C20.9947 10.1124 21.3649 9.55844 21.6175 8.94873C21.8701 8.33902 22.0001 7.68547 22.0001 7.02551C22.0001 6.36555 21.8701 5.71201 21.6175 5.10229C21.3649 4.49258 20.9947 3.93867 20.528 3.47205Z" fill="currentColor"></path>
                                                                            <path d="M14.132 9.86804C13.6421 9.37931 13.0561 8.99749 12.411 8.74695L12 9.15698C11.6234 9.53421 11.4119 10.0455 11.4119 10.5785C11.4119 11.1115 11.6234 11.6228 12 12C12.3766 12.3772 12.5881 12.8885 12.5881 13.4215C12.5881 13.9545 12.3766 14.4658 12 14.843L8.44699 18.396C8.06999 18.773 7.55868 18.9849 7.02551 18.9849C6.49235 18.9849 5.98101 18.773 5.604 18.396C5.227 18.019 5.0152 17.5077 5.0152 16.9745C5.0152 16.4413 5.227 15.93 5.604 15.553L8.74701 12.411C8.28705 11.233 8.28705 9.92498 8.74701 8.74695C8.10159 8.99737 7.5152 9.37919 7.02499 9.86804L3.47198 13.421C2.52954 14.3635 2.00009 15.6417 2.00009 16.9745C2.00009 18.3073 2.52957 19.5855 3.47202 20.528C4.41446 21.4704 5.69269 21.9999 7.02551 21.9999C8.35833 21.9999 9.63656 21.4704 10.579 20.528L14.132 16.975C14.5987 16.5084 14.9689 15.9544 15.2215 15.3447C15.4741 14.735 15.6041 14.0815 15.6041 13.4215C15.6041 12.7615 15.4741 12.108 15.2215 11.4983C14.9689 10.8886 14.5987 10.3347 14.132 9.86804Z" fill="currentColor"></path>
                                                                            </svg>
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                                <div class="ms-2">
                                                                    <button type="button" @click="this.onDownloadFile(imageItem)" class="btn btn-sm btn-icon btn-light btn-active-light-primary">
                                                                        <span class="svg-icon svg-icon-5 m-0"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                                                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                                                                        </svg>
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                                <div class="ms-2">
                                                                    <button type="button" @click="this.onDeleteFile(imageItem)" class="btn btn-sm btn-icon btn-light btn-active-light-primary">
                                                                        <span class="svg-icon svg-icon-5 m-0">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"></path>
                                                                            <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"></path>
                                                                            <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"></path>
                                                                            </svg>
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                                
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div> 
                    </div>
                </div>
            </div>
            <div class="modal fade" action="none" id="kt_modal_upload_form"  data-bs-backdrop="static" data-bs-keyboard="false">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <form class="form" action="#" id="kt_modal_upload_form">
                            <div class="modal-header p-4 ms-5">
                                <h2 class="fw-bold">Upload File</h2> 
                                <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal">
                                    <span class="svg-icon svg-icon-1"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                    </svg>
                                    </span>
                                </div>
                            </div>
                            <div class="modal-body">
                                <div class="form-group">
                                    <div id="kt_modal_upload_dropzone">
                                        <div class="col-lg-12">  
                                            <DxFileUploader
                                            multiple="false"
                                            upload-mode="useButtons" 
                                            :visible="true" 
                                            accept= "*"
                                            :upload-headers="getUploadHeaders()"
                                            upload-url="/api/MediaLibrary/Upload"
                                            :on-uploaded="getMediaData"
                                            :ref="'fileUploader'"/>
                                        </div>
                                    </div>
                                    <span class="form-text fs-6 text-muted ms-3">Max file size is 1MB per file.</span>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal fade" tabindex="-1" id="add_folder_modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header py-4">
                            <div class="col-lg-6">
                                <h2 class="modal-title fw-bolder">
                                    Add Folder      
                                </h2>  
                            </div>
                            <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeAddFolderModal">
                                <span class="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                        viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                            transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                        <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                            transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                    </svg>
                                </span>
                            </div>
                        </div> 
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="p-5">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="input-group mb-4 mt-4">
                                                    <span class="input-group-text" id="basic-addon1">/</span> 
                                                    <input class="form-control" v-model="this.form.folder_name" placeholder="Folder Name" /> 
                                                </div>
                                            </div>
                                            <div class="col-lg-12 text-start">
                                                <button class="btn btn-primary w-100 mt-4" @click="this.onCreateFolder()">
                                                    <span class="svg-icon svg-icon-primary svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor"/>
                                                        <path opacity="0.3" d="M10.3 15.3L11 14.6L8.70002 12.3C8.30002 11.9 7.7 11.9 7.3 12.3C6.9 12.7 6.9 13.3 7.3 13.7L10.3 16.7C9.9 16.3 9.9 15.7 10.3 15.3Z" fill="currentColor"/>
                                                        <path d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM11.7 16.7L16.7 11.7C17.1 11.3 17.1 10.7 16.7 10.3C16.3 9.89999 15.7 9.89999 15.3 10.3L11 14.6L8.70001 12.3C8.30001 11.9 7.69999 11.9 7.29999 12.3C6.89999 12.7 6.89999 13.3 7.29999 13.7L10.3 16.7C10.5 16.9 10.8 17 11 17C11.2 17 11.5 16.9 11.7 16.7Z" fill="currentColor"/>
                                                        </svg>
                                                    </span>
                                                    Create Folder
                                                </button>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" tabindex="-1" id="open_download_modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header py-4">
                            <div class="col-lg-6">
                                <h2 class="modal-title fw-bolder">
                                    Document Link      
                                </h2>  
                            </div>
                            <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeAddModal">
                                <span class="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                        viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                            transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                        <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                            transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                    </svg>
                                </span>
                            </div>
                        </div> 
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="p-5">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="fs-6 text-dark">Copy Link</div>
                                                <input class="form-control form-control-sm" v-model="this.imageUrl" ref="text">
                                            </div>
                                            <div class="col-lg-12 text-start">
                                                <button class="btn btn-primary w-100 mt-4"  @click="this.copyLink()">
                                                    <span class="svg-icon svg-icon-primary svg-icon-2">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor"/>
                                                        <path opacity="0.3" d="M10.3 15.3L11 14.6L8.70002 12.3C8.30002 11.9 7.7 11.9 7.3 12.3C6.9 12.7 6.9 13.3 7.3 13.7L10.3 16.7C9.9 16.3 9.9 15.7 10.3 15.3Z" fill="currentColor"/>
                                                        <path d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM11.7 16.7L16.7 11.7C17.1 11.3 17.1 10.7 16.7 10.3C16.3 9.89999 15.7 9.89999 15.3 10.3L11 14.6L8.70001 12.3C8.30001 11.9 7.69999 11.9 7.29999 12.3C6.89999 12.7 6.89999 13.3 7.29999 13.7L10.3 16.7C10.5 16.9 10.8 17 11 17C11.2 17 11.5 16.9 11.7 16.7Z" fill="currentColor"/>
                                                        </svg>
                                                    </span>
                                                    Copy Link
                                                </button>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>   
    </div>  
</template>
  
<script> 

import axios from 'axios'

import moment from 'moment'; 
import { DxFileUploader} from 'devextreme-vue/file-uploader';


export default {
    name: "MediaLibrary",
    data() {
      return {
        activeFolder: {
            id: null,
            name : null,
            url: null
        },
        downloadForm: {
            download_reason: null
        },
        requestedFile: null,
        imageList:[],
        mediaFolderList:[],
        id: null, 
        form :{
            folder_name : null        
        },
        message: '',
        imageUrl: null,
        isViewUploadButton: false, 
        searchbar: '',
      }
    },
    components:{
       DxFileUploader
    },
    computed:{
        previewDate(){
            return (value)=>{
            if (value) {
                return moment(new Date(value)).format('DD.MM.YYYY HH:mm')
                }
            }
        },
        calculateSize(){
        return (bytes)=>{
                if (bytes) 
                {
                    const decimals = 2;
                    const k = 1024;
                    const dm = decimals < 0 ? 0 : decimals;
                    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']; 
                    const i = Math.floor(Math.log(bytes) / Math.log(k)); 
                    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) +' '+ sizes[i];
                }
            } 
        },
        searchableImageList() {
        return this.imageList.filter(p => { 
            return p.name.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
        });
    },
    },
    methods: {
        getData(){ 
            this.requestedFile = null;
            const parameters = { 
                session_id: this.$getUser().session_id,
            }; 
            axios.post('/api/MediaLibrary/GetMediaFolders', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                this.mediaFolderList = response.data;
                if(this.mediaFolderList.length > 0){
                    this.onFolderOpened(this.mediaFolderList[0]); 
                    this.$refs.fileUploader.instance.reset(); 
                    this.isViewUploadButton = true
                }  
            }) 
            .catch(function (error) {
                console.log(error);
                this.Progress.finish();
            });   
        },
        getMediaData(){ 
            this.requestedFile = null;
            const parameters = { 
                session_id: this.$getUser().session_id,
            }; 
            axios.post('/api/MediaLibrary/GetMediaFolders', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                this.mediaFolderList = response.data;
                if(this.mediaFolderList.length > 0){ 
                    this.$refs.fileUploader.instance.reset(); 
                    this.isViewUploadButton = true
                } 
                this.onFolderOpened(this.activeFolder);
            }) 
            .catch(function (error) {
                console.log(error);
                this.Progress.finish();
            });   
        },
        onCreateFolder(){
             const parameters = { 
                session_id: this.$getUser().session_id,
                folder_name: this.form.folder_name,
            }; 
            axios.post('/api/MediaLibrary/CreateMediaFolder', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == '')
                    {  
                        this.mediaFolderList = response.data;
                        this.$swal("Success", "Folder has been created successfully..!", 'success');
                        this.getData();
                        this.$refs.closeAddFolderModal.click(); 
                    } 
            }) 
            .catch(function (error) {
                console.log(error); 
            });
        },
        getUploadHeaders(){
            return { 
                sid: this.$getUser().session_id,
                cid: this.$getUser().company_account_id,
                ctd: this.$getUser().controller_account_id,
                fid: this.activeFolder.id,
                url: window.location.origin + '/assets/uploads/' + this.activeFolder.folder_name
            };
        },
        openFolderModal(){
            this.form.folder_name = null
        },
        deleteMediaFolder(data) {
         this.$swal.fire({
                title: "Are you sure you want to delete this data?",
                confirmButtonColor: "#E53935",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "Yes",
                icon: 'warning'
                })
                .then((result) => {
                if (result.isConfirmed) {
                    const parameters = { 
                        session_id: this.$getUser().session_id,
                        id: data.id,
                        fid : this.activeFolder.folder_name
                    }; 
                    axios.post('/api/MediaLibrary/DeleteMediaFolder', parameters, {'Content-Type': 'application/json'})
                    .then((response) => {
                        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == '')
                        {  
                            this.$swal("Deleted.", "Folder has been deleted..!", 'warning');  
                            this.getData(); 
                            this.isViewUploadButton = false
                        }
                    }) 
                    .catch(function (error) {
                        console.log(error);
                        this.Progress.finish();
                    });          
                }
            });
        },
        onFolderOpened(folderItem){
            this.activeFolder = folderItem;
            const parameters = { 
                session_id: this.$getUser().session_id,
                folder_id: folderItem.id
            }; 
            axios.post('/api/MediaLibrary/GetMediaDocument', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                    this.imageList = response.data;
                 }
            }) 
            .catch(function (error) {
                console.log(error);
                this.Progress.finish();
            });          
        },
        onDownloadFile(item){
            this.requestedFile = item;
               const config = { 
                    'Content-type': 'application/json',
                    responseType: 'blob',
                    headers: {
                        sid: this.$getUser().session_id,
                        cid: this.$getUser().company_account_id,
                        ctd: this.$getUser().controller_account_id,
                     }
            }; 
            const parameters = {
                name: this.requestedFile.name,
                fid:  this.requestedFile.fid,
                id:  this.requestedFile.id,
                rsn: this.downloadForm.download_reason,
                folder_name : this.activeFolder.folder_name
            };
            axios.post('/api/MediaLibrary/Download', parameters, config)
                .then((response) => { 
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', parameters.name);
                        document.body.appendChild(link);
                        link.click();
                    }
                }) 
                .catch(function (error) {
                console.log(error);
                this.Progress.finish();
                }); 
        },
        onDeleteFile(item){
            this.$swal.fire({
                title: "Are you sure you want to delete this document?",
                confirmButtonColor: "#f1416c",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "YES, DELETE IT",
                icon: 'info'
            })
            .then((result) => {
                if (result.isConfirmed) {  
                    const parameters = { 
                        session_id: this.$getUser().session_id,
                        id: item.id,
                        fnm: item.name,
                        fid: this.activeFolder.folder_name,
                    }; 
                    axios.post('/api/MediaLibrary/DeleteMediaDocument', parameters, {'Content-Type': 'application/json'})
                    .then((response) => {
                        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                            this.getMediaData();
                            }
                    }) 
                    .catch(function (error) {
                        console.log(error);
                        this.Progress.finish();
                    });        
                }
            });   
        },
        onCopyLink(item){
            this.imageUrl =window.location.origin + '/assets/uploads/' + this.activeFolder.folder_name + '/' + item.name
            this.copyLink();
        },
        copyLink() {
            this.$refs.text.select();
            document.execCommand('copy');
        },
        openUploadMedia(){
            this.$refs.fileUploader.instance.reset(); 

        }
    },
    created() {
        this.getData();

    }
  }; 
</script>