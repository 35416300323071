<template>
  <div>  
    <div class="d-flex flex-stack flex-wrap">
      <div class="page-title d-flex flex-column me-3 mt-3"> 
        <h1 class="d-flex text-dark fw-bolder my-1 fs-3">
          Report Designer 
        </h1>  
        <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
            <li class="breadcrumb-item text-gray-600">
                  <a href="#" class="text-gray-600 text-hover-primary"><router-link :to="{ name: 'Home' }">Home</router-link></a>
            </li> 
            <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
            <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'SystemSettings' }">System Settings</router-link></li>  
            <li class="breadcrumb-item text-gray-600">Add Report Design</li> 
        </ul> 
      </div>  
      <div class="d-flex align-items-center py-1">
        <router-link to="/SystemSettings">
          <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
            <span class="ms-2 fs-7"> Return back to Settings </span>
          </button>
        </router-link>
      </div> 
    </div>
    <div class="content flex-row-fluid mt-4" id="kt_content">
      <div class="card">
        <div class="card-header border-0 pt-6">                  
          <div class="card-title">
            <button type="button" class="btn btn-light-primary border border-primary" ref="openDesignModal"
              data-bs-toggle="modal" data-bs-target="#add_document_design_modal" @click="openDesignModal('addDesign')">
              <span class="svg-icon svg-icon-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                      transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                  <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                      fill="currentColor">
                  </rect>
                </svg>
              </span>
              Add Design
            </button>
          </div>
          <div class="card-toolbar">
            <button type="button" class="btn btn-light-primary border border-primary"  @click="getDocumentDesigner()">
              <span class="svg-icon svg-icon-muted svg-icon-2">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.3" d="M4.05424 15.1982C8.34524 7.76818 13.5782 3.26318 20.9282 2.01418C21.0729 1.98837 21.2216 1.99789 21.3618 2.04193C21.502 2.08597 21.6294 2.16323 21.7333 2.26712C21.8372 2.37101 21.9144 2.49846 21.9585 2.63863C22.0025 2.7788 22.012 2.92754 21.9862 3.07218C20.7372 10.4222 16.2322 15.6552 8.80224 19.9462L4.05424 15.1982ZM3.81924 17.3372L2.63324 20.4482C2.58427 20.5765 2.5735 20.7163 2.6022 20.8507C2.63091 20.9851 2.69788 21.1082 2.79503 21.2054C2.89218 21.3025 3.01536 21.3695 3.14972 21.3982C3.28408 21.4269 3.42387 21.4161 3.55224 21.3672L6.66524 20.1802L3.81924 17.3372ZM16.5002 5.99818C16.2036 5.99818 15.9136 6.08615 15.6669 6.25097C15.4202 6.41579 15.228 6.65006 15.1144 6.92415C15.0009 7.19824 14.9712 7.49984 15.0291 7.79081C15.0869 8.08178 15.2298 8.34906 15.4396 8.55884C15.6494 8.76862 15.9166 8.91148 16.2076 8.96935C16.4986 9.02723 16.8002 8.99753 17.0743 8.884C17.3484 8.77046 17.5826 8.5782 17.7474 8.33153C17.9123 8.08486 18.0002 7.79485 18.0002 7.49818C18.0002 7.10035 17.8422 6.71882 17.5609 6.43752C17.2796 6.15621 16.8981 5.99818 16.5002 5.99818Z" fill="currentColor"/>
                  <path d="M4.05423 15.1982L2.24723 13.3912C2.15505 13.299 2.08547 13.1867 2.04395 13.0632C2.00243 12.9396 1.9901 12.8081 2.00793 12.679C2.02575 12.5498 2.07325 12.4266 2.14669 12.3189C2.22013 12.2112 2.31752 12.1219 2.43123 12.0582L9.15323 8.28918C7.17353 10.3717 5.4607 12.6926 4.05423 15.1982ZM8.80023 19.9442L10.6072 21.7512C10.6994 21.8434 10.8117 21.9129 10.9352 21.9545C11.0588 21.996 11.1903 22.0083 11.3195 21.9905C11.4486 21.9727 11.5718 21.9252 11.6795 21.8517C11.7872 21.7783 11.8765 21.6809 11.9402 21.5672L15.7092 14.8442C13.6269 16.8245 11.3061 18.5377 8.80023 19.9442ZM7.04023 18.1832L12.5832 12.6402C12.7381 12.4759 12.8228 12.2577 12.8195 12.032C12.8161 11.8063 12.725 11.5907 12.5653 11.4311C12.4057 11.2714 12.1901 11.1803 11.9644 11.1769C11.7387 11.1736 11.5205 11.2583 11.3562 11.4132L5.81323 16.9562L7.04023 18.1832Z" fill="currentColor"/>
                </svg>
              </span>
              List Design
            </button>
          </div> 
        </div>
        <div class="card-body">  
          <div class="dataTables_wrapper dt-bootstrap4 no-footer">
            <div class="table-responsive min-h-300px">
              <DxDataGrid :show-borders="true" key-expr="id" :data-source="designList" :allow-column-reordering="true" :allow-column-resizing="true" :column-auto-width="true" :row-alternatin-enabled="true" :hover-state-enabled="true" :show-row-lines="true" @exporting="this.$onExporting($event , 'Report-Design')">
                <DxFilterRow :visible="true"/>
                <DxHeaderFilter :visible="true"/>
                <DxGroupPanel :visible="true"/>      
                <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                <DxPaging :page-size="20"/>
                <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                <DxSearchPanel :visible="true"/>

                <DxColumn data-field="actions" caption="Actions" :width="130"  cell-template="actions-cell-template" alignment="center" :allow-filtering="false" :allow-exporting="false"/>
                <DxColumn data-field="name" caption="Name"  cell-template="name-cell-template"/> 
                <DxColumn data-field="category_name" caption="Category"  cell-template="category-cell-template" alignment="left"/> 
                <DxColumn data-field="receipt_type_name" caption="Receipt Type"  cell-template="receipt_type_name-cell-template" alignment="left"/> 
                <DxColumn data-field="view_option_name" caption="View Option"  cell-template="view_option_name-cell-template" alignment="left"/> 
                <DxColumn data-field="report_order" caption="Order"  cell-template="report_order-cell-template"/>
                <DxColumn data-field="description" caption="Description"  cell-template="description-cell-template" alignment="left"/>

                <template #created_at-header>
                    <div>Created</div><div>At</div>
                </template> 

                <!-- Cell Templates -->
                <template #name-cell-template="{ data }">
                    <span class="text-gray-900 fw-bold mb-1">
                        {{ data.data.name }}
                    </span>
                </template>
                <template #category-cell-template="{ data }">
                  <div>
                    <span class="text-gray-900 fw-bold mb-1">
                        {{data.data.category_name}}
                    </span>
                  </div>
                </template>
                <template #receipt_type_name-cell-template="{ data }">
                    <span class="text-gray-900 fw-bold mb-1" v-if="data.data.category == 2">
                      {{ data.data.receipt_type_name }}
                    </span>
                    <i class="bi bi-dash text-gray-900 fw-bold mb-1" v-else></i>
                </template>
                <template #view_option_name-cell-template="{ data }">
                    <span class="text-gray-900 fw-bold mb-1">
                      {{ data.data.view_option_name }}
                    </span>
                </template>
                <template #description-cell-template="{ data }">
                    <span class="text-gray-900 fw-bold mb-1">
                        {{ data.data.description }}
                    </span>
                </template>           
                <template #report_order-cell-template="{ data }">
                    <span class="text-gray-900 fw-bold mb-1">
                        {{ data.data.report_order }}
                    </span>
                </template>           
                <template #actions-cell-template="{ data }">
                  <div class="d-grid gap-4 d-md-flex justify-content-md-center">
                    <button class="btn btn-primary btn-xs" type="button" data-bs-toggle="modal" data-bs-target="#add_document_design_modal"
                    @click="openDesignModal('updateDesign', data.data.id)">Update</button>
                    <button class="btn btn-danger btn-xs" type="button"  @click="deleteDesign(data.data.id)">Delete</button>
                  </div>
                </template>
              </DxDataGrid>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Create Document Designer Modal -->
    <div class="modal fade" aria-hidden="true" style="display: none;" id="add_document_design_modal" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-fullscreen-xl-down">        
          <div class="modal-content">
              <div class="modal-header">
                <h3 class="modal-title fw-bolder col-md-10">
                    <span class="fw-bolder"  v-if="designModalType == 'addDesign'" >Create Design</span>
                    <span class="fw-bolder"  v-if="designModalType == 'updateDesign'" >Update Design</span>
                    <!-- <span class="fw-bolder" v-if="this.liabilityModalType == 'editItem'"> Edit Liability </span> -->                  
                </h3>
                <div class="btn btn-icon btn-sm btn-active-icon-primary">
                    <i class="bi bi-gear fs-2 ms-3 mt-1 cursor-pointer float-end"></i>  
                </div> 
                <div class="btn btn-icon btn-sm btn-active-icon-primary"
                    data-bs-dismiss="modal" aria-label="Close" ref="closeDesignerModal">
                    <span class="svg-icon svg-icon-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                            <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                        </svg>
                    </span>
                </div>
              </div>
              <div class="modal-body">
                <div class="row">
                    <div class="col-lg-12">
                      <label class="fs-6 fw-bold form-label mt-3">Category </label>
                      <Select2 v-model="designForm.category" :options="categories" :settings="{width: '100%'}"  @select="getDocumentDesignsByCategory()"/>
                    </div>
                    <div class="col-lg-12" v-if="designForm.category == 2">
                      <label class="fs-6 fw-bold form-label mt-3">Receipt Type </label>
                      <Select2 v-model="designForm.receipt_type" :options="receiptTypeList" :settings="{width: '100%'}" />
                    </div>
                    <div class="col-lg-12" v-if="designForm.category != 2">
                      <label class="fs-6 fw-bold form-label mt-3">View Option </label>
                      <Select2 v-model="designForm.view_option" :options="viewOptionList" :settings="{width: '100%'}" />
                    </div>
                    <div class="col-lg-12">
                      <label class="fs-6 fw-bold form-label mt-3">Name</label>
                      <input type="text" class="form-control" placeholder="" name="name" v-model="designForm.name"> 
                    </div>                 
                    <div class="col-lg-12">
                      <label class="fs-6 fw-bold form-label mt-3">Description</label> 
                      <textarea rows="3" class="form-control" name="address" v-model="designForm.description"></textarea>
                    </div>                    
                    <div class="col-lg-12">
                      <label class="fs-6 fw-bold form-label mt-3">Order</label> 
                      <input type="number" class="form-control" placeholder="" name="report_order" v-model="designForm.report_order"> 
                    </div>                    
                    <div class="col-lg-12" v-if="designModalType == 'addDesign'">
                      <label class="fs-6 fw-bold form-label mt-3">Design Template</label> 
                      <Select2 v-model="designForm.design_template_id" :options="defaultDesignList" :settings="{width: '100%'}" />
                    </div>                    
                    <div class="col-lg-12">
                        <div class="d-grid gap-2 mt-5"> 
                          <button type="button" class="btn btn-success btn-lg" v-if="designModalType == 'addDesign'"  @click="saveDesign()" >
                            <span class="indicator-label">Create Design </span> 
                          </button>
                          <button type="button" class="btn btn-primary btn-lg"  v-if="designModalType == 'updateDesign'"  @click="updateModalDesign()">
                              <span class="indicator-label">Update Data </span> 
                          </button>
                          <div class="separator my-3 mt-0 border-gray-300 mt-3"></div>
                          <button type="button" class="btn btn-success btn-lg" v-if="designModalType == 'updateDesign'" ref="openEditDesignModal" data-bs-toggle="modal" data-bs-target="#edit_document_design_modal" @click="onOpenDesignerModal()">
                            <span class="indicator-label"> 
                              <span class="svg-icon svg-icon-muted svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M20 14H11C10.4 14 10 13.6 10 13V10C10 9.4 10.4 9 11 9H20C20.6 9 21 9.4 21 10V13C21 13.6 20.6 14 20 14ZM21 20V17C21 16.4 20.6 16 20 16H11C10.4 16 10 16.4 10 17V20C10 20.6 10.4 21 11 21H20C20.6 21 21 20.6 21 20Z" fill="currentColor"/>
                                <path d="M20 7H3C2.4 7 2 6.6 2 6V3C2 2.4 2.4 2 3 2H20C20.6 2 21 2.4 21 3V6C21 6.6 20.6 7 20 7ZM7 9H3C2.4 9 2 9.4 2 10V20C2 20.6 2.4 21 3 21H7C7.6 21 8 20.6 8 20V10C8 9.4 7.6 9 7 9Z" fill="currentColor"/>
                                </svg>
                                </span>
                                Open Designer
                              </span> 
                          </button> 
                        </div>
                    </div>
                </div>
              </div>
          </div>
      </div>
    </div> 
    <!-- Edit modal -->
    <div class="modal fade" aria-hidden="true" style="display: none;" id="edit_document_design_modal" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-fullscreen">        
          <div class="modal-content">
              <div class="modal-header">
                <h3 class="modal-title fw-bolder col-md-11">
                    <span class="fw-bolder" >Edit Design 
                      <small class="ms-5" v-if="this.designForm.name !== null && this.designForm.name !== ''"> ({{this.designForm.name}}) </small>
                    </span>        
                </h3>
                <div class="btn btn-icon btn-sm btn-active-icon-primary">
                  <i class="bi bi-gear fs-2 ms-3 mt-1 cursor-pointer float-end"></i>
                </div> 
                <div class="btn btn-icon btn-sm btn-active-icon-primary"
                    data-bs-dismiss="modal" aria-label="Close" ref="closeDesignEditModal" @click="onCloseDesignEditModal(designForm)">
                    <span class="svg-icon svg-icon-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                            <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                        </svg>
                    </span>
                </div>
              </div>
              <DesignerModal ref="DesignerModal" v-if="ifDesignerModalOpened"/>
          </div>
      </div>
    </div>
  </div> 
</template>

<script>
import axios from 'axios' 

import moment from 'moment';
import Swal from 'sweetalert2';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel } from 'devextreme-vue/data-grid';
import DesignerModal from '../Accounts/Company/DesignerModal.vue';

 
export default {
  name: "ReportDesign",
  components:{
      DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DesignerModal
  },
  data() {
    return {

      designList: [],
      defaultDesignList: [{ id: '-1', text: "Select an option" }],
      categories: [{ id: '-1', text: "Select an option" }, { id: 1, text: "Invoice" }, { id: 2, text: "Receipt" }, { id: 3, text: "Statement" }, { id: 4, text: "Stock Statement" },{id:5,text:"Offer"}],
      viewOptionList: [{ id: 0, text: "Show in All" }, { id: 1, text: "Show in Local Currency" }, { id: 2, text: "Show in Foreign Currency" }],
      receiptTypeList: [{ id: 0, text: "Cash/Bank" }, { id: 1, text: "Offsetting" }, { id: 2, text: "Cheque" }, { id: 3, text: "Expense" }],
      designForm: {
        id: null,        
        category: '-1',
        name: null,
        description: null,
        design_template_id: '-1',
        receipt_type: 0,
        report_order: 0,
        view_option: 0
      },
      designModalType: 'addDesign',
      ifDesignerModalOpened: false,
      pageSizes: [10, 20, 50, 100, 'all'],
    };
  },
  computed: {
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY HH:mm')
        }
      }
    }		
	},
  methods: {
    openDesignModal(modalType, designId, isOpenEditModal) {
      this.designModalType = modalType;
      this.ifDesignerModalOpened = false;
      this.designForm = {
        id: null,
        category: '-1',
        name: null,
        description: null,
        design_template_id: '-1',
        receipt_type: 0,
        report_order: 0,
        view_option: 0
      }
      if(modalType == 'updateDesign') {
        const parameters = {
          session_id: this.$getUser().session_id,
          id: designId
        }
        axios.post('/api/DocumentDesign/GetDesignById', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
           if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
              this.designForm = response.data;
              this.$setSessionStorage('report_design_id', designId);
              this.$setSessionStorage('report_type', this.designForm.category);
              if(isOpenEditModal) {
                const modalButton = this.$refs.openEditDesignModal;
                modalButton.click();
              }
           }
        })
        .catch(function (error) {
           alert(error);
        });
      }
    },
    saveDesign(){
      const parameters = this.designForm;
      parameters.session_id = this.$getUser().session_id;
      if(parameters.category == '-1') {parameters.category = 0;}
      if(parameters.design_template_id == '-1') {parameters.design_template_id = null;}
      axios.post('/api/DocumentDesign/Create', parameters, {'Content-Type': 'application/json'}).then((response) => {
        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
          this.openDesignModal('updateDesign', response.data, true);
          this.getDocumentDesigner();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    updateModalDesign(){
      const parameters = this.designForm;
      parameters.session_id = this.$getUser().session_id;
      if(parameters.category == '-1') {parameters.category = 0;}
      if(parameters.design_template_id == '-1') {parameters.design_template_id = null;}
      parameters.id = this.$getSessionStorage("report_design_id");
      axios.post('/api/DocumentDesign/Update', parameters, {'Content-Type': 'application/json'}).then((response) => {
        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
          this.$refs.closeDesignerModal.click();
          this.getDocumentDesigner();
        }
      })
      .catch(function (error) {
        console.log(error);
        this.$swal("Failed", "Design could not be saved..Please refresh the page and try again..!");
      });
    },
    getDocumentDesigner() {
      const parameters = {
        session_id: this.$getUser().session_id,
      };
      axios.post('/api/DocumentDesign/GetDesigns', parameters, {'Content-Type': 'application/json'}).then((response) => { 
        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){          
          this.designList = response.data;
        }
      })
      .catch(function (error) {
        console.log(error); 
      });
    },
    getDocumentDesignsByCategory() {
      const parameters = {
        session_id: this.$getUser().session_id,
        category: this.designForm.category !== '-1' ? this.designForm.category : null
      };
      axios.post('/api/DocumentDesign/GetDesigns', parameters, {'Content-Type': 'application/json'}).then((response) => { 
        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){          
          this.defaultDesignList = response.data;
          this.defaultDesignList.unshift({id: '-1', text: 'Select an option'})
        }
      })
      .catch(function (error) {
        console.log(error); 
      });
    },
    onCloseDesignEditModal(designForm) {
      const modalButton = this.$refs.openDesignModal;
      modalButton.click();
      this.designModalType = 'updateDesign';
      this.designForm = designForm;
    },
    deleteDesign(designId){ 
      this.$swal.fire({
        title: 'Are you sure you want to delete this design?',
        confirmButtonColor: '#f1416c',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        icon: 'warning'
      }).then((result) => { 
        if (result.isConfirmed) {
          const parameters = {
            session_id: this.$getUser().session_id,
            id: designId
          };
         
          axios.post('/api/DocumentDesign/Delete', parameters, {'Content-Type': 'application/json'})
              .then((response) => {
                  if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                      new Swal( { icon: 'error', title: 'Deleted...', text: 'Data deleted..!' });
                      this.getDocumentDesigner();                      
                  }
          })
          .catch(function (error) {
              console.log(error);
          });
        }
      })    
    },
    onOpenDesignerModal(){
      this.ifDesignerModalOpened = true;
    }
  }
}; 
</script>