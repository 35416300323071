import { createApp } from 'vue'

import App from './App.vue'
import router from './router' 
import vuex from 'vuex'
import VueSweetalert2 from 'vue-sweetalert2';
import Select2 from 'vue3-select2-component';
import Datepicker from '@vuepic/vue-datepicker';
import VueProgressBar from '@aacassandra/vue3-progressbar';
import ColorInput from 'vue-color-input';  
import Maska from 'maska';
import CurrencyInput from "./directives/CurrencyInput.vue";
// import VeeValidate from "vee-validate";
import Pagination from 'v-pagination-3';
import Popper from "vue3-popper";
import mitt from 'mitt';
import NoTableResults from "./directives/NoTableResults.vue";
import RequiredField from "./directives/RequiredField.vue";
import Vidle from 'v-idle';
import  Editor  from '@tinymce/tinymce-vue';
import 'sweetalert2/dist/sweetalert2.min.css';   
import '@vuepic/vue-datepicker/dist/main.css';
import 'devextreme/dist/css/dx.light.css';
import '/src/assets/css/custom.css';  
import store from "./store"; 
import SecureLS from "secure-ls";  
import { jsPDF } from 'jspdf';
import { exportDataGrid  as exportDataGridToPdf} from 'devextreme/pdf_exporter';
import { exportDataGrid  as exportDataGridToXls } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import CryptoJS from 'crypto-js';

const emitter = mitt();
const app = createApp(App); 
const ls = new SecureLS({ isCompression: false });

/** PROTOTYPES **/
app.config.globalProperties.$getUser = function() { 
  var data = {};
  try{
    data = (sessionStorage.getItem('sid') == null || sessionStorage.getItem('sid') == undefined || sessionStorage.getItem('sid') == '') ? ls.get('user') : ls.get('user_' + sessionStorage.getItem('sid'));
  }
  catch(Err){
    console.error('Error: while parsing User Storage data: ' + Err);
  }
  return data;  
};
app.config.globalProperties.$setSessionStorage = function(key, data) {
  if(key !== null && key !== undefined && key !== '' && data !== null && data !== undefined && data !== ''){
    try{
      sessionStorage.setItem(key, JSON.stringify(data));
    }
    catch(Err){
      console.error('Error: while parsing Session Storage data.');
    }
  }   
};
app.config.globalProperties.$removeUser = function(key) {
  if(key !== null && key !== undefined && key !== ''){
    ls.remove(key);
  }   
};
app.config.globalProperties.$getSessionStorage = function(key) {
  var data = null;
  try{
    if(sessionStorage.getItem(key) !== null && sessionStorage.getItem(key) !== undefined && sessionStorage.getItem(key) !== ''){
      data = JSON.parse(sessionStorage.getItem(key));
    }
  }
  catch(Err){
    if(sessionStorage.getItem(key).length == 36){
      data = sessionStorage.getItem(key);
    } 
  }
  return data; 
};
app.config.globalProperties.$removeSessionStorage = function(key) {
  if(key !== null && key !== undefined && key !== ''){
    try{
      sessionStorage.removeItem(key);
    }
    catch(Err){
      console.error('Error: while parsing Session Storage data.');
    }
  }   
};
app.config.globalProperties.$onExporting= function(e,listName) {
  if (e.format === 'xlsx') {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Sheet');

      exportDataGridToXls({
          component: e.component,
          worksheet,
          autoFilterEnabled: true,
          customizeCell({ gridCell, excelCell }) {
            if (gridCell.rowType === 'data' && gridCell.column.dataField === 'is_default') {
              excelCell.value = gridCell.value === 1 ? 'True' : 'False';
            }
            if (gridCell.rowType === 'data' && gridCell.column.dataField === 'list_type') {
              excelCell.value = gridCell.value === 1 ? 'Liability' : '';
            }
            if (gridCell.rowType === 'data' && gridCell.column.dataField === 'is_active') {
              excelCell.value = gridCell.value === 1 ? 'Active' : 'Passive';
            }
          }
      }).then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), listName + '.xlsx');
          });
      });
      e.cancel = true;
  }
  else if (e.format === 'pdf') { 
      const doc = new jsPDF({
          format: 'a3',
          orientation: 'landscape'
      });
      doc.addFont('../public/assets/fonts/Roboto-Regular.ttf','Roboto-Regular','normal')
      doc.setFont('Roboto-Regular');
      const lastPoint = { x: 0, y: 0 };
      exportDataGridToPdf({
          jsPDFDocument: doc,
          component: e.component,
          indent: 2,
          customizeCell({ gridCell, pdfCell }) {
              if (gridCell.rowType === 'group') {
                  pdfCell.backgroundColor = '#FFE082';
              } 
              if (gridCell.rowType === 'data' && gridCell.column.dataField === 'record_date') {
                  if(pdfCell.text == '1'){
                      pdfCell.backgroundColor = '#FFE082';
                  }
              }
              if (gridCell.rowType === 'data' && gridCell.column.dataField === 'is_default') {
                pdfCell.text = gridCell.value === 1 ? 'Active' : 'Passive';
              }
              if (gridCell.rowType === 'data' && gridCell.column.dataField === 'list_type') {
                pdfCell.text = gridCell.value === 1 ? 'Liability' : '';
              }
              if (gridCell.rowType === 'data' && gridCell.column.dataField === 'is_active') {
                pdfCell.text = gridCell.value === 1 ? 'Active' : 'Passive';
              }

          }
      }).then(() => {
          const footer = 'CreatorCUBE by Polisoft Software © 2022';
          doc.setFontSize(9);
          doc.setTextColor('#cccccc');
          const footerWidth = doc.getTextDimensions(footer).w;
          doc.text(footer, (lastPoint.x - footerWidth), lastPoint.y + 5);
          doc.save(listName + '.pdf');
      });
  }
};
app.config.globalProperties.$lock = function(value){
  return this.$CryptoJS.AES.encrypt(value, "UxLPuYFIzdOPKcTW").toString();
};
app.config.globalProperties.$isSystemAdmin = function () {
    return this.$getUser().account_type == 1 && this.$getUser().permission_type == 1;
}
app.config.globalProperties.$unlock = function(encryptedText){
  encryptedText = CryptoJS.enc.Base64.parse(encryptedText);
  var key = CryptoJS.enc.Utf8.parse("UxLPuYFIzdOPKcTW");
  var aes = CryptoJS.algo.AES.createDecryptor(key, {
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
    iv: key
  });
  var decrypted = aes.finalize(encryptedText);
  return CryptoJS.enc.Utf8.stringify(decrypted);
};
const loadingBarOptions = {
    color: "#009ef7",
    failedColor: "#E91E63",
    thickness: "7px",
    transition: {
      speed: "0.2s",
      opacity: "0.6s",
      termination: 300,
    },
    autoRevert: true,
    location: "top",
    inverse: false,
  }; 

app.use(router);
app.use(vuex);
app.use(store);
app.use(VueSweetalert2);   
app.use(VueProgressBar, loadingBarOptions);
app.use(ColorInput);
app.use(Maska);
app.use(CryptoJS);
// app.use(VeeValidate); 
app.component('Select2', Select2)   
app.component('Datepicker', Datepicker);  
app.component('CurrencyInput', CurrencyInput);
app.component('pagination', Pagination); 
app.component('NoTableResults', NoTableResults);
app.component("RequiredField", RequiredField);
app.component("Popper", Popper);
app.component("Editor", Editor);
app.component("Vidle", Vidle); 
app.config.globalProperties.emitter = emitter;
app.mount('#app');
