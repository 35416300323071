<template>
  <div>  
    <div class="d-flex flex-stack flex-wrap">
      <div class="page-title d-flex flex-column me-3 mt-3"> 
          <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Create Controller User
          </h1>  
          <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
              <li class="breadcrumb-item text-gray-600">
                  <a href="#" class="text-gray-600 text-hover-primary"><router-link :to="{ name: 'Home' }">Home</router-link></a>
              </li> 
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'ControllerSettings' }">Controller Settings</router-link></li>  
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'ControllerUserList' }">Controller Users</router-link></li> 
              <li class="breadcrumb-item text-gray-600">Create Controller User</li> 
          </ul> 
      </div>  
      <div class="d-flex align-items-center py-1">
          <router-link to="/ControllerUserList">
              <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
              <span class="ms-2 fs-7"> Return back to List</span>
              </button>
          </router-link> 
      </div>
    </div>
    <div class="content flex-row-fluid mt-4" id="kt_content">
      <RequiredField></RequiredField>
      <div class="tab-content" id="myTabContent">
        <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
          <div class="p-10">
            <div class="row">
              <div class="d-flex flex-column flex-row-auto col-lg-12"> 
                <div class="d-flex flex-column-fluid card card-xxl-stretch">
                  <div class="alert alert-dismissible bg-light-warning border border-warning d-flex flex-column flex-sm-row w-100 p-5 mb-5">
                    <i class="ki-duotone ki-search-list fs-2hx text-primary me-4 mb-5 mb-sm-0"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
                    <div class="d-flex flex-column pe-0 pe-sm-10">
                        <span> A verification link will be sent to the entered e-mail address. The account must be verified by setting a password on the link sent. 
                          The user will not be able to access the system until the account is verified.
                        </span>
                    </div>
                    <button type="button" class="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
                        <i class="ki-duotone ki-cross fs-1 text-primary"><span class="path1"></span><span class="path2"></span></i>
                    </button>
                  </div>
                </div> 
              </div>
              <div class="d-flex flex-column flex-row-auto col-lg-6">
                <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                  <div class="card-body pb-0">
                    <form id="kt_ecommerce_settings_general_form" class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                      <div class="fv-row mb-7 row">
                        <div class="col-lg-12">
                          <label class="fs-6 fw-bold form-label mt-3 required">
                            Controller Account
                          </label>
                          <Select2 v-model="form.controller_account_id" :options="controllerAccountList" :settings="{width: '100%'}"
                            :disabled="this.authUserAccountType !== 1" @select="onChangeControllerAccount($event)" />
                        </div>
                        <div class="col-lg-12">
                          <label class="fs-6 fw-bold form-label mt-3">
                            Name, Surname
                          </label>
                          <input type="text" class="form-control" placeholder="" name="name" v-model="form.name_surname"> 
                        </div>
                        <div class="col-lg-12">
                            <label class="fs-6 fw-bold form-label mt-3 required">
                                Email
                            </label>
                            <input type="text" class="form-control" placeholder="" name="name" v-model="form.user_mail" autocomplete="off" :class="{'border border-danger': isValidEmail == false}"> 
                            <small class="text-danger" v-if="!isValidEmail && form.user_mail !== null && form.user_mail !== '' ">Email is not valid</small>
                        </div>
                        <!-- <div class="col-lg-6">
                          <div class="form-check form-switch form-check-custom mt-5">
                            <input class="form-check-input" type="checkbox" v-model="form.isActive" id="isActive" :disabled="form.is_mail_verified == 0"/>
                            <label class="form-check-label form-label mt-2" for="isActive">
                              Is Active?
                            </label>
                          </div>
                        </div> -->
                        <!-- <div class="col-lg-12">
                            <label class="fs-6 fw-bold form-label mt-3 required">
                                Password
                            </label>
                            <div class="position-relative">
                            <input id="ps_Password" type="password" class="form-control" placeholder="" name="name" v-model="form.user_password" autocomplete="off">
                            <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" @click="togglePasswordVisibility()">
                                <i v-if="isHide" class="fa fa-eye-slash fs-2 mb-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>
                                <i v-if="!isHide" class="fa fa-eye fs-2 mb-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
                            </span> 
                            </div>
                        </div> -->
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-column flex-row-auto col-lg-6">
                <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                  <div class="card-body pb-0">
                    <form id="kt_ecommerce_settings_general_form" class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                      <div class="fv-row mb-7 row">
                        <div class="col-lg-12">
                            <label class="fs-6 fw-bold form-label mt-3">
                              Phone
                            </label>
                            <div class="input-group">
                              <span class="input-group-text fs-7 text-primary" id="basic-addon1" v-if="form.controller_account_id !== '-1'">
                                {{form.company_country_code}} {{form.phone_country_code}}
                              </span>
                              <input type="text" class="form-control" name="phone" v-model="form.phone">
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <label class="fs-6 fw-bold form-label mt-3">
                              Permission Type
                            </label>
                            <Select2 v-model="form.permission_type" :options="permissionTypes" :settings="{width: '100%'}" />
                        </div>
                        <div class="col-lg-12">
                          <label class="fs-6 fw-bold form-label mt-3">
                            User Assignment 
                          </label>
                          <Select2 v-model="form.user_assignment_id" :options="userAssignments" :settings="{width: '100%'}" />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                  <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                      <div class="card-body pb-0">
                          <form id="kt_ecommerce_settings_general_form"
                              class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                              <div class="fv-row mb-7 row">
                                  <div class="col-lg-6">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                      Login Method
                                    </label> 
                                    <Select2 v-model="form.login_method" :options="loginMethods" :settings="{width: '100%'}" />
                                  </div>
                                  <div class="col-lg-6" v-if="form.login_method == 2">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                      Authentication Type
                                    </label> 
                                    <Select2 v-model="form.authentication_type" :options="authenticationTypes" :settings="{width: '100%'}" />
                                  </div>
                                  <div class="col-lg-6">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                      Validity Type
                                    </label> 
                                    <Select2 v-model="form.validity_type" :options="validityTypes" :settings="{width: '100%'}" />
                                  </div>
                                  <div class="col-lg-6" v-if="form.validity_type == 2">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                      Validity Start Date
                                      <small v-if="form.validity_date_start == null" class="text-danger ms-3"> Please enter a valid date </small>
                                    </label> 
                                    <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.validity_date_start" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" />
                                  </div>
                                  <div class="col-lg-6" v-if="form.validity_type == 2">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                      Validity End Date
                                      <small v-if="form.validity_date_end == null" class="text-danger ms-3"> Please enter a valid date </small>
                                    </label> 
                                    <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.validity_date_end" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" />  
                                  </div>
                                  <div class="col-lg-4">
                                      <label class="fs-6 fw-bold form-label mt-3">Is Owner?</label> 
                                      <br>
                                      <input class="mt-3" type="checkbox" id="checkbox" v-model="form.isOwner" />
                                  </div>
                                  <div class="col-lg-4">
                                      <label class="fs-6 fw-bold form-label mt-3">Is ChatBi User?</label> 
                                      <br>
                                      <input class="mt-3" type="checkbox" id="checkbox" v-model="form.isChatbiUser" />
                                  </div>
                                  <div class="col-lg-12">
                                      <label class="fs-6 fw-bold form-label mt-3">
                                          Description
                                      </label>
                                      <textarea type="text" rows="2" class="form-control" placeholder="" name="name" v-model="form.user_description" /> 
                                  </div>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
              <div class="col-lg-12 mt-0">
                <div class="d-grid gap-2"> 
                    <button type="button" class="btn btn-lg btn-success" @click="this.saveData()" data-kt-indicator="on" :disabled="isValidEmail == false">
                        <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                        <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                        </svg>
                        </span>
                          <strong v-if="!this.isProgressing"> Save Controller User </strong> 
                            <span class="indicator-progress" v-if="this.isProgressing">
                                Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span> 
                    </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
  </div> 
</template>

<script>
// Component Alias: `CUC001`
import axios from 'axios' 
  
// import Swal from 'sweetalert2';

import moment from 'moment'; 
 
export default {
  name: "ControllerUserCreate",
  data() {
    return {
      lists: [],
      isProgressing: false,
      accountTypes: [{ id: 1, text: "System User" }, { id: 2, text: "Controller User" }, { id: 3, text: "Company User" }],
      permissionTypes: [{ id: 1, text: "Admin" }, { id: 2, text: "Client" }],
      loginMethods: [{ id: 1, text: "Standard" }, { id: 2, text: "2FA" }],
      authenticationTypes: [{ id: 1, text: "SMS" }, { id: 2, text: "Google 2FA" }],
      validityTypes: [{ id: 1, text: "Always" }, { id: 2, text: "Date Range" }],
      userAssignments: [],
      companyAccounts: [{ id: null, title: null }],
      controllerAccountList: [],
      form: { 
          account_type: 2,
          permission_type: 1,
          company_account_id: null,
          controller_account_id: '-1',
          user_assignment_id: '-1',
          user_mail: null,
          user_password: null,
          name_surname: null,
          phone: null,
          user_description: null,
          login_method : 1,
          authentication_type: 1,
          validity_type: 1,
          validity_date_start: new Date(),
          validity_date_end: new Date(),
          password_validity: -1,
          is_active: 1,
          isActive: true,
          isOwner: false,
          is_owner: 0,
          session_id: null,
          isChatbiUser: false,
          is_chatbi_user: 0,
          phone_country_code: null,
          company_country_code: null
      },
      isHide: true,
      authUserAccountType: null
    };
  },
  computed: {
    isValidEmail() {
			return (this.form.user_mail == null || this.form.user_mail == '' || this.form.user_mail == undefined) ? null : /^[^@]+@\w+(\.\w+)+\w$/.test(this.form.user_mail);
		}
  },
  methods: {
    getData() {
      this.authUserAccountType = this.$getUser().account_type;
      this.controllerAccountList = [];
      this.userAssignments = [];  
      this.$setSessionStorage('refreshList', true);

      const parameters = {
        session_id: this.$getUser().session_id,
        get_access_status: 0,
        list_type: 18, // User Assignment List: 18
        account_type: 2
      };  
      
      const requestOne =  axios.post('/api/Accounts/ControllerAccount', parameters, {'Content-Type': 'application/json'});
      const requestTwo =  axios.post('/api/Lists/GetLists', parameters, {'Content-Type': 'application/json'});
      const requestThree =  axios.post('/api/GeneralUserParameters/Get', parameters, {'Content-Type': 'application/json'});
      axios.all([requestOne, requestTwo, requestThree]).then(axios.spread((...responses) => {
        this.controllerAccountList = responses[0].data;
        this.controllerAccountList.forEach(element => {
          element.company_name = this.$unlock(element.company_name);
          element.text = this.$unlock(element.text);
          element.company_vkn = this.$unlock(element.company_vkn);
          element.primary_person = this.$unlock(element.primary_person);
          element.company_gsm = this.$unlock(element.company_gsm);
          element.company_landphone = this.$unlock(element.company_landphone);
          element.company_email = this.$unlock(element.company_email);
          element.company_address = this.$unlock(element.company_address);
          element.company_city = this.$unlock(element.company_city);
          element.company_town = this.$unlock(element.company_town);
          element.company_state = this.$unlock(element.company_state);
          element.company_region = this.$unlock(element.company_region);
          element.company_postcode = this.$unlock(element.company_postcode);
          element.company_country_code = this.$unlock(element.company_country_code);
          element.currency_code = this.$unlock(element.currency_code);
          element.phone_country_code = this.$unlock(element.phone_country_code);
        });
        this.controllerAccountList.unshift({ id: '-1', text:  'Select an option'});
        if(this.$getUser().controller_account_id !== '' && this.$getUser().controller_account_id !== null && this.$getUser().controller_account_id !== undefined ) {
          this.form.controller_account_id = this.$getUser().controller_account_id;
        }
        this.userAssignments = responses[1].data;
        this.userAssignments.unshift({ id: '-1', text:  'Select an option'});
        
        if(!this.$isSystemAdmin()) {
          this.userAssignments.forEach((value) => {
            if(value.is_default) {
              this.form.user_assignment_id = value.id;
            }
          });
        }

        this.form.login_method = responses[2].data.login_method == 0 ? 1 : responses[2].data.login_method;
        this.form.authentication_type = responses[2].data.authentication_type == 0 ? 1 : responses[2].data.authentication_type;
        this.form.validity_type = responses[2].data.validity_type == 0 ? 1 : responses[2].data.validity_type;
        this.form.validity_date_start = responses[2].data.validity_date_start;
        this.form.validity_date_end = responses[2].data.validity_date_end;
      }))
      .catch(errors => { 
        console.error(errors); 
      });
    },
    togglePasswordVisibility() {
      this.isHide = !this.isHide;
      var x = document.getElementById("ps_Password");
      if (x.type === "password") {
        x.type = "text";
      } else {
        x.type = "password";
      }
    },
    saveData(){
      this.isProgressing = true;
      const params = this.form; 
      const that = this; 
      params.session_id = this.$getUser().session_id;
      params.is_active = params.isActive == true ? 1 : 0;
      params.is_owner = params.isOwner == true ? 1 : 0;
      params.is_chatbi_user = params.isChatbiUser == true ? 1 : 0;
      params.company_account_id = null;
      if (params.user_assignment_id == '-1') {params.user_assignment_id = null;}
      if (params.controller_account_id == '-1') {params.controller_account_id = null;}
      if (params.validity_type == 2) {
        params.validity_date_start = params.validity_date_start !== null ? moment(params.validity_date_start).format('YYYY-MM-DD[T]HH:mm:ss') : null;
        params.validity_date_end = params.validity_date_end !== null ? moment(params.validity_date_end).format('YYYY-MM-DD[T]HH:mm:ss') : null;
      }

      if (this.$getUser().account_type == 1 && params.controller_account_id == null){
        this.$swal("Failed", "Controller account is required!", 'error');
        this.isProgressing = false;
        return;
      }
      axios.post('/api/Users/CreateUser', params, {'Content-Type': 'application/json'})
      .then((response) => { 
          if(response.data != null && response.data.length == 36){  
            this.$swal("Success", "Data saved succesfully..!", 'success');
            this.$setSessionStorage('refreshList', true);
            this.$setSessionStorage('record_id', response.data);
            this.sendVerifyLink();
          }
      })
      .catch(function (error) {
          console.log(error);
      })
      .finally(function () { 
          setTimeout(() => {
              that.isProgressing = false;
          }, 500);
      });   
    },
    sendVerifyLink() {
      const parameters = {
        session_id: this.$getUser().session_id,
        user_mail: this.form.user_mail,
        account_type: 2
      };  
      const that = this;
      axios.post('/api/Users/SendVerifyLink', parameters, {'Content-Type': 'application/json'})
      .then((response) => {
         if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
          this.$router.push({ name: 'ControllerUserUpdate' });
         }
      })
      .catch(function () {
         that.$swal("Failed", "An error occurred while sending the email. You can wait for a while to resend or check the mail server settings.", 'error');
         that.$router.push({ name: 'ControllerUserUpdate' });
      });
    },
    onChangeControllerAccount(event) {
      this.form.company_country_code = event.company_country_code;
      this.form.phone_country_code = event.phone_country_code;
    }
  },
  mounted() {
    this.$setSessionStorage('refreshList', true)
    this.getData();
  }
}; 
</script>